import "./App.css";
import logo_text from "./images/logo_text.png";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function App() {
    return (
        <div className="solar_bg flex flex-col items-center">
            <img
                className="w-auto h-10 mt-4 tabletPortrait:h-16 lg:h-16 drop-shadow-sm"
                src={logo_text}
                alt="Logo + Text"
            />
            <p className="drop-shadow-sm leading-normal manrope-sp_gmbh text-sp_blue text-2xl text-center mt-8 tabletPortrait:text-4xl lg:text-5xl lg:leading-tight">
                Ihr Partner für{" "}
                <span className="lg:hidden">
                    <br />
                </span>
                <span className="text-sp_orange">nachhaltige</span> Energie
            </p>
            <div className="w-10/12 h-[24rem] bg-sp_blue/75 mt-24 tabletPortrait:mt-44 desktop:mt-64 flex flex-col items-center justify-evenly lg:flex-row drop-shadow-sm">
                <div className="flex flex-col">
                    <p className="leading-normal manrope-sp_gmbh text-white text-2xl text-center tabletPortrait:text-3xl lg:text-3xl lg:text-start">
                        Kontakt:
                    </p>
                    <a
                        className="leading-normal manrope-sp_gmbh text-white text-lg text-center mt-8 tabletPortrait:mt-16 lg:mt-16 tabletPortrait:text-2xl lg:text-lg lg:text-start"
                        href="tel:+41 76 706 54 71"
                    >
                        <FontAwesomeIcon
                            className="w-5 h-5 tabletPortrait:w-7 tabletPortrait:h-7 text-white mr-4"
                            icon={faPhone}
                        />
                        +41 76 706 54 71
                    </a>
                    <a
                        className="leading-normal manrope-sp_gmbh text-white text-lg text-center mt-4 tabletPortrait:text-2xl lg:text-lg lg:text-start"
                        href="mailto:info@sunpower-swiss.ch"
                    >
                        <FontAwesomeIcon
                            className="w-5 h-5 tabletPortrait:w-7 tabletPortrait:h-7 text-white mr-4"
                            icon={faEnvelope}
                        />
                        info@sunpower-swiss.ch
                    </a>
                </div>
                <p className="leading-normal manrope-sp_gmbh text-sp_orange text-2xl text-center mt-2 tabletPortrait:mt-10 tabletPortrait:text-4xl lg:text-4xl lg:leading-8">
                    Webseite befindet sich{" "}
                    <span className="hidden lg:flex">
                        <br />
                    </span>{" "}
                    im Aufbau.
                </p>
            </div>
        </div>
    );
}

export default App;
